import axios from 'axios';
import { authorizedURL, authorizeRequest } from 'services/auth';

export const DMP_PRIVATE_MEDIA_PROXY = process.env.PRIVATE_MEDIA_PROXY;
export const DMP_BASE_URL = process.env.DMP_HOST;
export const MEDIA_BASE_URL = `${DMP_BASE_URL}/media`;
export const UPLOAD_BASE_URL = `${DMP_PRIVATE_MEDIA_PROXY}/uploads`;

const dmp = axios.create({ baseURL: DMP_BASE_URL, withCredentials: true });
dmp.interceptors.request.use(authorizeRequest);
const parseError = e => e.response ? e.response : { status: '', statusText: e.toString() };

export const getUpload = (uploadId, source) => dmp.get(`/manage/upload/${uploadId}?source=${source}`);

export const importPost = postId => dmp.get(`/manage/post/import/${postId}`);

export const playIncoming = (uploadId, itemId) => authorizedURL(
  `${DMP_PRIVATE_MEDIA_PROXY}/manage/upload/${uploadId}/item/${itemId}/play?source=incoming`,
);

export const cancelPostJobs = postId => dmp.delete(`/manage/upload/${postId}/jobs`);

// https://cloud.google.com/apis/design/custom_methods
export const cancelPostsJobs = postIds => dmp.post('/manage/upload/jobs:cancel', { uuids: postIds });

export const redoPostJobs = postId => dmp.get(`/manage/rpc/upload/${postId}/redo`);

export const redoPostsJobs = postIds => dmp.post('/manage/rpc/upload/jobs:redo', { uuids: postIds });

export const deleteConversionArchive = postId => dmp.delete(`/manage/post/${postId}/conversionarchive`);

export const deleteConversionArchives = postIds => dmp.post('/manage/post/conversionarchive:delete',
  { uuids: postIds },
);

export const exportConversionArchive = (ids, callback) => {
  if (ids) {
    dmp.post('/manage/conversionarchive/export', { uuids: ids }).then((res) => callback && callback(res.data));
  } else {
    dmp.post('/manage/conversionarchive/export').then((res) => callback && callback(res.data));
  }
};

export const importConversionArchive = (data) => dmp.post('/manage/conversionarchive/import', data);

export const signedPdfUrl = uploadId => authorizedURL(`${DMP_BASE_URL}/manage/upload/${uploadId}/signed_pdf`);

export const originalUrl = ({ mediaType, media_type, directory, path, original, source }) => {
  const mt = mediaType || media_type;
  return authorizedURL(
    mt === 'image' || mt === 'audio' ?
      (directory ?
        `${UPLOAD_BASE_URL}/${original}?disposition=attachment` :
        `${UPLOAD_BASE_URL}/${path}`) :
      `${UPLOAD_BASE_URL}/${source === 'incoming' || source === 'archived' || original === undefined ?
        path : original}?disposition=attachment`,
  );
};

export const versionUrl = (uploadId, itemId, version) => authorizedURL(
  `${DMP_BASE_URL}/manage/upload/${uploadId}/item/${itemId}/download?version=${version}`,
);

export const downloadAllUrl = (uploadId, source) => authorizedURL(
  `${DMP_BASE_URL}/manage/upload/${uploadId}/download?source=${source}`,
);

const manageJob =  action => id => dmp.get(`/manage/rpc/work/${id}/${action}`);

export const pauseJob = manageJob('pause');
export const resumeJob = manageJob('resume');
export const abortJob = manageJob('abort');
export const setJobPriority = (id, priority) => dmp.get(`/manage/rpc/queue/${id}/priority/${priority}`);

export const getJobHistory = params => dmp.get('/manage/job', { params });
export const getDonePostsHistory = params => dmp.get('/manage/post/done', { params });
export const getCanceledPostsHistory = params => dmp.get('/manage/post/canceled', { params });
export const deleteJob = id => dmp.delete(`/manage/rpc/queue/${id}`);

export const getUsersList = async role => {
  try {
    const { data } = await dmp.get('/manage/user', role ? {  params: { role } } : undefined);
    return data;
  } catch (e) {
    throw parseError(e);
  }
};

export const createUser = async (name, email, role) => {
  try {
    const { data } = await dmp.post('/manage/user', { name, email, role });
    return data;
  } catch (e) {
    throw parseError(e);
  }
};

export const deleteUser = async email => {
  try {
    const { data } = await dmp.delete(`/manage/user/${email}`);
    return data;
  } catch (e) {
    throw parseError(e);
  }
};


export const createPost = (post, publishWhenDone) => dmp.post(
  `/manage/upload/${post.upload_id}/accept`,
  { upload: post },
  publishWhenDone ? { params: { 'publish-when-done': true } } : undefined,
);

export const getPosts = (filter, page=0, media_type) => dmp.get(
  `/mobile_api/json/items/${filter}/${page}`,
  {
    params: media_type ? { media_type } : {},
  });

export const archivePost = ({ upload_id }, note) => dmp.post(
  `/manage/rpc/upload/${upload_id}/archive`,
  { note },
  { params: { source: 'accepted' } },
);

export const updatePost = (post, source='accepted') =>
  post.upload_id ? dmp.patch(
    '/manage/post',
    post,
    { params: { source } },
  )
    : Promise.reject(new Error('post.upload_id must be set'));

export const getPostStats = (postId) => dmp.get(
  `/mobile_api/json/itemstats?compositeId=${postId}`,
);

export const updatePostStats = (postId, stats) => dmp.post(
  '/mobile_api/json/itemstats/',
  [{id: postId, stats}],
);

export const makeSubs = (language, uploadId, itemId, translate) => dmp.post('/manage/upload/transcription', {
  language: language,
  upload_id: uploadId,
  item_id: itemId,
  translate: translate,
});




