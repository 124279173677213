import React from 'react';
import tw from 'twin.macro';

export const Progress = ({ value, max = 100, color = 'success', size = 'default' }) => {
  const percentage = Math.min(100, Math.max(0, (value / max) * 100));
  const sizeStyles = {
    sm: tw`h-1`,
    default: tw`h-2`,
    lg: tw`h-3`,
  };

  const colorStyles = {
    success: tw`bg-green-500`,
    info: tw`bg-blue-500`,
    warning: tw`bg-yellow-500`,
    danger: tw`bg-red-500`,
  };

  return (
    <div css={[tw`flex w-full bg-gray-200 rounded-full overflow-hidden`, sizeStyles[size]]}>
      <div
        css={[tw`transition-all duration-300 ease-in-out`, colorStyles[color]]}
        style={{ width: `${percentage}%` }}
      />
    </div>
  );
};
