import React from 'react';
import tw from 'twin.macro';

export const Dialog = ({ isOpen, onClose, title, children, maxWidth = 'md' }) => {
  if (!isOpen) { return null; }
  
  const maxWidthClasses = {
    sm: tw`max-w-sm`,
    md: tw`max-w-md`,
    lg: tw`max-w-lg`,
    xl: tw`max-w-xl`,
    '2xl': tw`max-w-2xl`,
    full: tw`max-w-full`,
  };
  
  // Handle ESC key to close dialog
  React.useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    
    window.addEventListener('keydown', handleEsc);
    
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [onClose]);
  
  // Prevent body scrolling when dialog is open
  React.useEffect(() => {
    document.body.style.overflow = 'hidden';
    
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);
  
  return (
    <div 
      css={[
        tw`fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-50`,
      ]}
      onClick={(e) => {

        // Close when clicking the backdrop
        if (e.target === e.currentTarget) {
          onClose();
        }
      }}
    >
      <div 
        css={[
          tw`bg-white rounded-lg shadow-xl w-full overflow-hidden`,
          maxWidthClasses[maxWidth],
        ]}
      >
        <div tw="flex justify-between items-center p-4 border-b">
          <h2 tw="text-xl font-semibold">{title}</h2>
          <button 
            tw="text-gray-500 hover:text-gray-700 focus:outline-none rounded-full p-1 hover:bg-gray-100"
            onClick={onClose}
            aria-label="Close"
          >
            ✕
          </button>
        </div>
        <div tw="p-4">
          {children}
        </div>
      </div>
    </div>
  );
};
