import React, { useCallback, useState, useRef } from 'react';
import tw from 'twin.macro';
import { upload } from 'services/uploads';
import { FileUploadIcon } from 'icons';

// Create fallback icons if CheckCircleIcon and XCircleIcon are not available
const CheckCircleIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19
      8l-9 9z"
      clipRule="evenodd"
    />
  </svg>
);

const XCircleIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7
      15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"
      clipRule="evenodd"
    />
  </svg>
);
import { useShowErrorMessage, useUnmounted } from '../common/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthUser } from '../auth/ducks';
import { useHistory } from 'react-router-dom';
import { postsFetchOp } from '../posts/ducks';
import { Button } from '../ui/Button';
import { Dialog } from '../ui/Dialog';
import { Progress } from '../ui/Progress';

// Tabs component for upload options
const UploadTabs = ({ activeTab, setActiveTab }) => {
  const tabs = [
    { id: 'upload', label: 'Bestand uploaden' },
    { id: 'url', label: 'URL' },
  ];

  return (
    <div tw="border-b mb-4">
      <div tw="flex space-x-2">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            onClick={() => setActiveTab(tab.id)}
            css={[
              tw`px-4 py-2 text-sm font-medium transition-colors border-b-2`,
              activeTab === tab.id
                ? tw`border-blue-500 text-blue-600`
                : tw`border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300`,
            ]}
          >
            {tab.label}
          </button>
        ))}
      </div>
    </div>
  );
};

// Upload mode selection component
const UploadModeSelector = ({ uploadMode, setUploadMode }) => {
  const modes = [
    { id: 'single', label: 'Uploaden als één bericht' },
    { id: 'separate', label: 'Uploaden als aparte berichten' },
  ];

  return (
    <div tw="mb-4">
      <h3 tw="text-sm font-medium text-gray-700 mb-2">Kies hoe je de bestanden wilt uploaden</h3>
      <div tw="grid grid-cols-2 gap-3">
        {modes.map((mode) => (
          <button
            key={mode.id}
            type="button"
            onClick={() => setUploadMode(mode.id)}
            css={[
              tw`flex flex-col items-center justify-center p-4 border rounded-lg transition-all`,
              uploadMode === mode.id
                ? tw`border-blue-500 bg-blue-50 text-blue-700`
                : tw`border-gray-300 hover:bg-gray-50`,
            ]}
          >
            <span tw="font-medium">{mode.label}</span>
            {mode.id === 'separate' && (
              <span tw="text-xs text-gray-500 mt-1">
                Elk bestand wordt als een apart bericht geüpload. Voortgang wordt per bestand weergegeven.
              </span>
            )}
          </button>
        ))}
      </div>
    </div>
  );
};

// File upload form component integrated directly into CreateUploadButton
const FileUploadForm = ({ onUpload, isUploading, uploadProgress, uploadComplete, uploadError }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [activeTab, setActiveTab] = useState('upload');
  const [uploadMode, setUploadMode] = useState('single');
  const [url, setUrl] = useState('');
  const fileInputRef = useRef(null);

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handleFileSelect(e.dataTransfer.files);
    }
  };

  const handleFileSelect = (files) => {
    const fileArray = Array.from(files);
    setSelectedFiles(fileArray);

    if (fileArray.length > 0) {
      onUpload(fileArray, uploadMode);
    }
  };

  const handleClick = () => {
    if (!isUploading) {
      fileInputRef.current?.click();
    }
  };

  const handleUrlSubmit = (e) => {
    e.preventDefault();
    if (url && !isUploading) {

      // For now we'll just simulate a file upload
      const mockFile = new File(
        [''],
        url.split('/').pop() || 'remote-file',
        { type: 'application/octet-stream' },
      );
      onUpload([mockFile], uploadMode);
    }
  };

  const getFileTypeIcon = (fileType) => {
    if (fileType.startsWith('image/')) { return '🖼️'; }
    if (fileType.startsWith('video/')) { return '🎬'; }
    if (fileType.startsWith('audio/')) { return '🎵'; }
    return '📄';
  };

  const getUploadStatusUI = () => {
    if (uploadComplete) {
      return (
        <div tw="flex items-center justify-center p-4 bg-green-50 text-green-700 rounded-md mt-4">
          <CheckCircleIcon tw="w-5 h-5 mr-2" />
          <span>Upload voltooid!</span>
        </div>
      );
    }

    if (uploadError) {
      return (
        <div tw="flex items-center justify-center p-4 bg-red-50 text-red-700 rounded-md mt-4">
          <XCircleIcon tw="w-5 h-5 mr-2" />
          <span>Upload mislukt: {uploadError}</span>
        </div>
      );
    }

    if (isUploading) {
      return (
        <div tw="mt-4">
          <div tw="flex justify-between mb-1">
            <span tw="text-sm font-medium">Uploading...</span>
            <span tw="text-sm font-medium">{Math.round(uploadProgress * 100)}%</span>
          </div>
          <Progress
            value={Math.round(uploadProgress * 100)}
            color={uploadProgress < 0.3 ? 'info' : uploadProgress < 0.7 ? 'warning' : 'success'}
          />
        </div>
      );
    }

    return null;
  };

  return (
    <div tw="flex flex-col space-y-4">
      <UploadTabs activeTab={activeTab} setActiveTab={setActiveTab} />

      {activeTab === 'upload' && (
        <UploadModeSelector uploadMode={uploadMode} setUploadMode={setUploadMode} />
      )}

      {activeTab === 'upload' ? (
        <>
          <div
            className={`border-2 border-dashed rounded-lg p-6 text-center ${
              isDragging ? 'border-blue-500 bg-blue-50' : 'border-gray-300'
            }`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onClick={handleClick}
            css={[
              tw`cursor-pointer transition-colors duration-200 hover:bg-gray-50`,
              isUploading && tw`pointer-events-none opacity-75`,
            ]}
          >
            <input
              ref={fileInputRef}
              type="file"
              tw="hidden"
              onChange={(e) => handleFileSelect(e.target.files)}
              disabled={isUploading}
              multiple
            />

            <div tw="flex flex-col items-center justify-center space-y-2">
              <FileUploadIcon tw="w-12 h-12 text-gray-400" />
              <p tw="text-lg font-medium">Sleep bestanden hier</p>
              <p tw="text-sm text-gray-500">of klik om bestanden te selecteren</p>
            </div>
          </div>

          {selectedFiles.length > 0 && (
            <div tw="mt-4 bg-gray-50 rounded-lg p-4 border">
              <div tw="flex justify-between items-center mb-2">
                <h3 tw="font-medium">Geselecteerde bestanden ({selectedFiles.length})</h3>
                {selectedFiles.length > 0 && (
                  <button
                    onClick={() => setSelectedFiles([])}
                    tw="text-sm text-red-600 hover:text-red-800"
                    disabled={isUploading}
                  >
                    Alles wissen
                  </button>
                )}
              </div>
              <div tw="max-h-40 overflow-y-auto divide-y divide-gray-200">
                {selectedFiles.map((file, index) => (
                  <div key={index} tw="flex items-center py-2">
                    <div tw="flex items-center justify-center w-8 h-8 rounded-full bg-gray-100 mr-3">
                      <span>{getFileTypeIcon(file.type)}</span>
                    </div>
                    <div tw="flex-1 min-w-0">
                      <p tw="text-sm font-medium text-gray-900 truncate">{file.name}</p>
                      <p tw="text-xs text-gray-500">{(file.size / 1024 / 1024).toFixed(2)} MB</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      ) : (
        <div tw="bg-white rounded-lg border p-4">
          <form onSubmit={handleUrlSubmit}>
            <label htmlFor="url-input" tw="block text-sm font-medium text-gray-700 mb-1">
              Voer een URL in om te uploaden
            </label>
            <div tw="flex">
              <input
                id="url-input"
                type="url"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                placeholder="https://example.com/file.jpg"
                tw="flex-1 min-w-0 block w-full px-3 py-2 rounded-l-md border border-gray-300 shadow-sm
                 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                disabled={isUploading}
              />
              <Button
                type="submit"
                disabled={!url || isUploading}
                css={[tw`rounded-l-none`]}
              >
                Uploaden
              </Button>
            </div>
          </form>
        </div>
      )}

      {getUploadStatusUI()}
    </div>
  );
};

export const CreateUploadButton = () => {
  const unmounted = useUnmounted();
  const [uploadProgress, setUploadProgress] = useState(-1);
  const [showUploadForm, setShowUploadForm] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const authUser = useSelector(selectAuthUser);
  const history = useHistory();
  const dispatch = useDispatch();
  const uploading = uploadProgress > -1;

  const showErrorMessage = useShowErrorMessage();

  const uploadFiles = useCallback((files, mode = 'single') => {
    if (files.length > 0) {
      setUploadProgress(0);
      setUploadComplete(false);
      setUploadError(null);

      // Here we could handle different upload modes
      // For 'single', upload all files as one post
      // For 'separate', upload each file as a separate post

      // Handle the upload based on the selected mode
      const uploadWithMode = (mode === 'separate' && files.length > 1)
        ? Promise.all(
          files.map((file) =>
            upload(authUser, [file], (e) => {
              if (!unmounted.current) {

                // For separate uploads, show progress of current file
                setUploadProgress(e.loaded / e.total);
              }
            }),
          ),
        )
        : upload(authUser, files, (e) => {
          if (!unmounted.current) {
            setUploadProgress(e.loaded / e.total);
          }
        });

      uploadWithMode
        .then(() => {
          if (!unmounted.current) {
            setUploadComplete(true);

            // Wait a moment to show the success message before closing
            setTimeout(() => {
              if (!unmounted.current) {
                const { pathname, search } = history.location;
                if(pathname === '/posts') {
                  if(search) {
                    history.push(pathname);
                  } else {
                    dispatch(postsFetchOp('all', 0, 'all'));
                  }
                }
                setShowUploadForm(false);
              }
            }, 1500);
          }
        })
        .catch(error => {
          if (!unmounted.current) {
            setUploadError(
              error.message || 'Er is een fout opgetreden tijdens het uploaden',
            );
            showErrorMessage(error);
          }
        })
        .finally(() => {
          if (!unmounted.current) {
            setUploadProgress(-1);
          }
        });
    }
  }, [authUser, dispatch, history, showErrorMessage, unmounted]);

  const handleCloseDialog = () => {
    if (!uploading) {
      setShowUploadForm(false);
    }
  };

  return (
    <div tw="flex flex-col md:items-center">
      <Button
        variant="default"
        Icon={FileUploadIcon}
        onClick={() => setShowUploadForm(true)}
      >
        Redactie upload
      </Button>

      <Dialog
        isOpen={showUploadForm}
        onClose={handleCloseDialog}
        title="Upload bestanden"
        maxWidth="lg"
      >
        <FileUploadForm
          onUpload={uploadFiles}
          isUploading={uploading}
          uploadProgress={uploadProgress}
          uploadComplete={uploadComplete}
          uploadError={uploadError}
        />
      </Dialog>
    </div>
  );
};
