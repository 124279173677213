import React from 'react';
import tw from 'twin.macro';

export const Button = React.forwardRef(({
  children,
  variant = 'default',
  size = 'default',
  Icon,
  iconPosition = 'left',
  disabled = false,
  ...props
}, ref) => {
  const variants = {
    default: tw`bg-blue-500 text-white hover:bg-blue-600`,
    destructive: tw`bg-red-500 text-white hover:bg-red-600`,
    outline: tw`border border-gray-300 bg-transparent hover:bg-gray-100`,
    ghost: tw`bg-transparent hover:bg-gray-100`,
    link: tw`bg-transparent underline hover:no-underline p-0 h-auto`,
  };
  
  const sizes = {
    default: tw`h-10 py-2 px-4 text-sm`,
    sm: tw`h-8 py-1 px-3 text-xs`,
    lg: tw`h-12 py-3 px-6 text-base`,
    icon: tw`h-10 w-10 p-2`,
  };
  
  return (
    <button
      ref={ref}
      disabled={disabled}
      css={[
        tw`inline-flex items-center justify-center rounded-md font-medium transition-colors 
           focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2`,
        variants[variant],
        sizes[size],
        disabled && tw`opacity-50 cursor-not-allowed`,
      ]}
      {...props}
    >
      {Icon && iconPosition === 'left' && <Icon tw="mr-2 -ml-1 h-5 w-5" />}
      {children}
      {Icon && iconPosition === 'right' && <Icon tw="ml-2 -mr-1 h-5 w-5" />}
    </button>
  );
});

Button.displayName = 'Button';
