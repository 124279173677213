import React, { useCallback, useEffect, useState } from 'react';
import tw from 'twin.macro';
import { autoItem, itemMediaType, thumbLarge } from '../utils';
import Progress from 'react-bulma-components/cjs/components/progress';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { TabsView } from './TabsView';
import { MediaOptions } from './MediaOptions';
import { selectCurrentJobsByUpload } from '../current/ducks';
import { ItemsBar } from './ItemsBar';
import { AgnoVideoPlayer } from '../videoplayer';
import { useUpload, useGetIsRisk } from './hooks';
import { SpinnerIcon } from 'icons';
import { originalUrl, playIncoming } from 'services/dmp';
import { authorizedURL } from 'services/auth';
import { deleteItem, reorderItems, uploadItem } from 'services/uploads';
import { EmbedPreview } from './tabs';
import { useShowErrorMessage, useUnmounted } from '../common/hooks';
import { RichTextEditor } from './RichTextEditor';
import {
  CheckBox,
  CheckBoxGroup,
  ConfirmButtons,
  FormField,
  Input,
  Label,
  TagsEditor,
} from '../common';
import { setMessageAction, showFlashNotificationOp } from '../store';

const choosePostImageVariant = (variants) => variants.find((v) => v.version === 'foto') || variants[0];

const choosePostAudioVariant = (variants) => variants.find((v) => v.version === 'mp3') || variants[0];

const moveItem = (from, to, list) => {
  const item = list.splice(from, 1)[0];
  list.splice(to, 0, item);
};

const FormDivider = () => <div tw="w-full border-t border-gray-300"></div>;
export const uploadToPost = (upload) => {
  const keys = [
    'title',
    'description',
    'nsfw',
    'nopreroll',
    'secret',
    'scripts',
    'postStatus',
    'pubDate',
    'sources',
  ];
  
  const post = Object.entries(upload)
    .filter(([k, v]) => keys.includes(k) && v !== undefined)
    .reduce((o, [k, v]) => ({ ...o, [k]: v }), {}); // leave out undefined fields

  if (upload.files) {
    post.sources = upload.files ? upload.files.reduce((acc, item) => {
      if (item.upload_source) {
        acc[item.id] = item.upload_source;
      }
      return acc;
    }, {}) : null;
  } else {
    post.sources = upload.items ? upload.items.reduce((acc, item) => {
      if (item.upload_source) {
        acc[item.id] = item.upload_source;
      }
      return acc;
    }, {}) : null;
  }

  post.upload_id = upload.id;
  if (upload.tags !== undefined) {
    post.tags = upload.tags.join(' ');
  }
  if (upload.pubDate !== undefined) {
    post.date = upload.pubDate;
  }
  if (post.postStatus === 'draft') {
    post.date = null;
  } else if (post.postStatus === 'planned') {
    post.date = post.pubDate;
  } else if (post.postStatus === 'publish') {
    if (post.date === undefined) {
      post.date = upload.date;
    }
  }
  return { post, publishWhenDone: post.postStatus === 'publish' };
};

const AspectRatioContainer = ({ children, twitterEmbed = false }) => (
  <div
    tw="w-full flex justify-center relative"
    style={{ paddingTop: twitterEmbed ? '0' : 'calc(9 / 16 * 100%)' }}
  >
    <div
      css={[
        tw`absolute inset-0 w-full h-full bg-black`,
        twitterEmbed && tw`relative`,
      ]}
    >
      {children}
    </div>
  </div>
);

const NoRatioContainer = ({ children, minHeight = true }) => (
  <div
    tw="flex justify-center items-center bg-black"
    style={{ minHeight: minHeight ? '440px' : null }}
  >
    {children}
  </div>
);
export const UploadPreview = ({
  uploadId,
  source,
  onDataChange = () => null,
  onDataError,
  enabled = true,
}) => {
  const unmounted = useUnmounted();
  const dispatch = useDispatch();
  const jobsByUpload = useSelector(selectCurrentJobsByUpload);
  const { pending, data, error, change, revert } = useUpload(uploadId, source);
  const [selectedItem, setSelectedItem] = useState(null);
  const showErrorMessage = useShowErrorMessage();
  const assetURL = selectedItem && originalUrl(selectedItem);
  const mediaType = itemMediaType(selectedItem);
  const isAccepted = source !== 'incoming' && source !== 'archived';
  const hasPost = data && !!data.post && isAccepted;
  const isVideo = mediaType === 'video';
  const itemIndex =
    selectedItem &&
    data &&
    data.items &&
    data.items.findIndex((i) => i.id === selectedItem.id);

  const variants = hasPost
    ? (data.post.media &&
      ![null, undefined, -1].includes(itemIndex) &&
      itemIndex < data.post.media.length &&
      data.post.media[itemIndex] &&
      data.post.media[itemIndex].variants) || [
      { uri: assetURL, version: 'stream' },
    ]
    : [{ uri: assetURL, version: 'stream' }];
  const relatedJobs = (data && jobsByUpload[data.id]) || {};
  const uploadProgress = relatedJobs.total || 0;
  const today = new Date(Date.now());
  const optionPublishAvailable =
    !(relatedJobs.jobs && relatedJobs.jobs.length > 0) ||
    data.postStatus === 'publish';
  const [newItemUploadProgress, setNewItemUploadProgress] = useState(-1);

  const addItems = useCallback(
    (files) => {
      if (!files || files.length < 1) {
        return;
      }
      setNewItemUploadProgress(0);
      uploadItem(uploadId, files, source, (e) => {
        if (!unmounted.current) {
          setNewItemUploadProgress(e.loaded / e.total);
        }
      })
        .then((items) => {
          if (data && data.items) {
            data.items.push(...items);
            if (!unmounted.current) {
              setSelectedItem(data.items[data.items.length - 1]);
            }
          }
          dispatch(showFlashNotificationOp('saveSuccess'));
        })
        .catch(showErrorMessage)
        .finally(() => {
          if (!unmounted.current) {
            setNewItemUploadProgress(-1);
          }
        });
    },
    [data && data.items, uploadId, source],
  );

  const removeItem = useCallback(
    (itemId) =>
      deleteItem(uploadId, itemId, source)
        .then(() => {
          if (data && data.items) {
            const index = data.items.findIndex((item) => item.id === itemId);
            data.items.splice(index, 1);
            change({ items: data.items });
            if (
              selectedItem !== null &&
              selectedItem.id === itemId &&
              !unmounted.current &&
              data.items.length > 0
            ) {
              setSelectedItem(autoItem(data));
            }
          }
          dispatch(showFlashNotificationOp('saveSuccess'));
        })
        .catch(showErrorMessage),
    [data && data.items, selectedItem],
  );

  const removeItemPrompt = useCallback(
    (itemId) =>
      dispatch(
        setMessageAction({
          text: <div tw="text-center w-full">Zeker weten?</div>,
          hideCloseButton: true,
          options: <ConfirmButtons onConfirm={() => removeItem(itemId)} />,
        }),
      ),
    [removeItem],
  );
  const articleId = data?.post?.id.split('_')[0];
  const { isRisk, updateIsRisk } = useGetIsRisk(articleId);
  const reorder = useCallback(
    ({ source: src, destination: dest }) => {
      if (src.index !== dest.index) {
        moveItem(src.index, dest.index, data.items);
        if (data.post) {
          moveItem(src.index, dest.index, data.post.media);
        }
        reorderItems(
          data.id,
          data.items.map((i) => i.id),
          source,
        )
          .then(() => dispatch(showFlashNotificationOp('saveSuccess')))
          .catch((e) => {
            moveItem(dest.index, src.index, data.items);
            if (data.post) {
              moveItem(dest.index, src.index, data.post.media);
            }
            showErrorMessage(e);
          });
      }
    },
    [data && data.items, data && data.post, source],
  );

  useEffect(() => {
    onDataChange(data);

    // workaround to handle items references updated, consider to use selected item id state instead of object.
    if (selectedItem && data && !data.items.includes(selectedItem)) {
      setSelectedItem(
        data.items.find((x) => x.id === selectedItem.id) || data.items[0],
      );
    }
  }, [JSON.stringify(data)]);

  useEffect(() => {
    if (error && onDataError) {
      onDataError(error);
    }
  }, [error]);

  useEffect(() => {
    setSelectedItem(autoItem(data || {}));
  }, [data ? data.id : null]);

  useEffect(() => {
    if (data) {
      switch (data.postStatus) {
      case 'publish':
        revert('pubDate');
        break;
      case 'draft':
        if (data.pubDate) {
          change({ pubDate: null });
        }
        break;
      case 'planned':
        if (
          !data.pubDate ||
          new Date(data.pubDate).getTime() < today.getTime()
        ) {
          change({ pubDate: today });
        }
        break;
      default:
        break;
      }
    }
  }, [data ? data.postStatus : null]);

  return (
    <>
      {pending && <SpinnerIcon tw="h-8 w-8" />}
      {!pending && error && <>{error.message}</>}
      {!pending && !error && data && (
        <>
          <div tw="bg-white shadow sm:rounded-lg space-y-6 lg:col-start-1 shadow p-4">
            <div tw="flex items-center">
              <Label label="publicatie status" name="post-publish-status" />
              <div className="select is-small pl-2">
                <select
                  id="post-publish-status"
                  name="poststatus"
                  value={data.postStatus || 'draft'}
                  onChange={(e) =>
                    enabled ? change({ postStatus: e.target.value }) : ''
                  }
                >
                  {optionPublishAvailable && (
                    <option value="publish">meteen online</option>
                  )}
                  <option value="planned">gepland</option>
                  <option value="draft">ontwerp</option>
                </select>
              </div>
            </div>

            {['publish', 'planned'].includes(data.postStatus) && (
              <div tw="flex items-center">
                <Label label="publicatie datum" />
                <div className="pl-2">
                  {data.postStatus === 'publish' && (
                    <>
                      {((date) =>
                        date.toLocaleDateString() +
                      ' ' +
                      date.toLocaleTimeString())(
                        new Date(data.pubDate || Date.now()),
                      )}
                    </>
                  )}
                  {data.postStatus === 'planned' && (
                    <DatePicker
                      showTimeInput
                      dateFormat="MM/dd/yyyy HH:mm"
                      selected={data.pubDate}
                      startDate={today}
                      onChange={(v) => (enabled ? change({ pubDate: v }) : '')}
                    />
                  )}
                </div>
              </div>
            )}

            <FormField
              label="Titel"
              name="title"
              type="text"
              placeholder={data.title}
              autoComplete="off"
              value={data.title}
              onChange={(e) =>
                enabled ? change({ title: e.target.value }) : ''
              }
              FieldType={Input}
            />

            <Label label="Beschrijving" />

            <RichTextEditor
              text={data?.description}
              onChange={(t) => change({ description: t })}
              id="rich-text-editor"
              ctw={tw`bg-gray-200 text-black p-2 border border-gray-300 rounded-r-md`}
            />

            <TagsEditor
              tags={data.tags || []}
              onChange={(tags) => (enabled ? change({ tags }) : '')}
            />

            <FormDivider />

            <CheckBoxGroup>
              <CheckBox
                label="NSFW"
                name="nsfw"
                checked={data.nsfw}
                onChange={(e) =>
                  enabled ? change({ nsfw: e.target.checked }) : ''
                }
              />
              <CheckBox
                label="NSFA"
                name="nopreroll"
                checked={data.nopreroll}
                onChange={(e) =>
                  enabled ? change({ nopreroll: e.target.checked }) : ''
                }
              />
              <CheckBox
                label="Secret"
                name="secret"
                checked={data.secret}
                onChange={(e) =>
                  enabled ? change({ secret: e.target.checked }) : ''
                }
              />
              {data && data?.post && (
                <CheckBox
                  label="Risk"
                  name="risk"
                  checked={isRisk}
                  onChange={() => updateIsRisk(articleId)}
                  disabled={!data?.post?.date}  // Disable checkbox if date is not present
                  style={!data?.post?.date ? { color: 'gray', cursor: 'not-allowed' } : {}}
                />
              )}
            </CheckBoxGroup>

            <FormDivider />

            <TabsView
              upload={data}
              selectedItem={selectedItem}
              change={change}
              enabled={enabled}
            />
          </div>
    
          <div tw="bg-white shadow sm:rounded-lg lg:col-start-2 lg:col-span-2 shadow p-4">
            {selectedItem && (
              <>
                {isVideo &&
                  (!selectedItem.embed_url || selectedItem.embed_url === '') &&
                  hasPost && (
                  <>
                    <AgnoVideoPlayer
                      variants={variants.map((x) => ({
                        ...x,
                        uri: authorizedURL(x.uri),
                      }))}
                      related={undefined}
                      itemId={data.id}
                      title={data.title}
                      still={thumbLarge(selectedItem, true)}
                      duration={selectedItem.input.video.duration}
                      dateCreated={data.created}
                      tags={data.tags}
                      description={data.description}
                    />
                  </>
                )}
                {isVideo &&
                  (!selectedItem.embed_url || selectedItem.embed_url === '') &&
                  !hasPost && (
                  <AspectRatioContainer>
                    <video
                      tw="w-full h-full"
                      src={playIncoming(data.id, selectedItem.id)}
                      controls
                    />
                  </AspectRatioContainer>
                )}

                {isVideo && selectedItem.embed_url && (
                  <EmbedPreview
                    mediaItem={selectedItem}
                    EmbedRatioContainer={
                      selectedItem.embed_url.split(':')[0] === 'youtube' ||
                        selectedItem.embed_url.split(':')[0] === 'kijk.nl' ||
                        selectedItem.embed_url.split(':')[0] === 'twitter' ||
                        selectedItem.embed_url.split(':')[0] === 'twitch'
                        ? AspectRatioContainer
                        : NoRatioContainer
                    }
                  />
                )}

                {mediaType === 'image' && (
                  <AspectRatioContainer>
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      tw="h-full w-full block"
                      href={authorizedURL(choosePostImageVariant(variants).uri)}
                    >
                      <img
                        src={authorizedURL(
                          choosePostImageVariant(variants).uri,
                        )}
                        tw="h-full w-full object-contain"
                      />
                    </a>
                  </AspectRatioContainer>
                )}

                {mediaType === 'audio' && (
                  <AspectRatioContainer>
                    <audio
                      tw="h-full w-full object-contain"
                      src={authorizedURL(
                        choosePostAudioVariant(variants).uri,
                      )}
                      controls
                    />
                  </AspectRatioContainer>
                )}

                <ItemsBar
                  upload={data}
                  selectedItem={selectedItem}
                  onAddItems={isAccepted ? addItems : null}
                  onRemoveItem={isAccepted ? removeItemPrompt : null}
                  onSelectItem={setSelectedItem}
                  onReorderItem={isAccepted ? reorder : null}
                  uploadProgress={newItemUploadProgress}
                />
              </>
            )}

            {uploadProgress > 0 && (
              <div>
                <Progress
                  max={1}
                  value={uploadProgress}
                  color={
                    uploadProgress < 0.5
                      ? 'warning'
                      : uploadProgress === 1
                        ? 'success'
                        : 'info'
                  }
                  size="small"
                />
              </div>
            )}
            <FormDivider />
            <MediaOptions
              selectedItem={selectedItem}
              data={data}
              change={change}
              enabled={enabled}
              source={source}
            />
          </div>
        </>
      )}
    </>
  );
};
